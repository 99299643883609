@import "../../../scss/mixin";

.header {
  color: var(--color-text1);
  background-color: var(--color-bg3);
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.header__logo {
}

.header__nav {
  margin: 0 16px;
}

.header__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__theme-toggler {
  margin-right: 36px;
}

.header__lang-toggler {
  margin-right: 36px;
}

.header__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header__btn {
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    &--sign {
      min-width: 115px;

      svg {
        margin-right: 8px;
      }
    }
    &--create {
      padding: 8px 16px;
      margin-right: 24px;
      min-width: 160px;
      svg {
        margin-right: 8px;
      }
    }
  }
}

.dark {
  .header__btn--sign {
    border-width: 1px;
  }
}

@media screen and (max-width: 1140px) {
  .header__theme-toggler {
    margin-right: 16px;
  }

  .header__lang-toggler {
    margin-right: 16px;
  }

  .header__btns {
    .header__btn {
      &--sign {
        margin-right: 16px;
      }
    }
  }
}

//---main
[home-slide] {
  .header,
  .header__logo {
    @include trLinearSlow();
    transition-delay: 0.2s;
  }
}

[home-slide="2"] {
  .header {
    background-color: #ffcd1d;
  }
  .header__btn--create {
    background-color: #312f2f;
    color: #ffcd1d;
  }

  .header__btn--sign {
    border-color: #312f2f;
    color: #312f2f;
  }
  &.dark {
    .header__logo {
      color: #312f2f;
    }
    .header-nav__item {
      color: #312f2f;
      &:hover {
        border-bottom: 3px solid #312f2f;
      }
    }
  }
}

[home-slide="4"] {
  .header {
    background-color: #06938c;
  }
  .header__logo {
    color: #ffffff;
  }
  .header-nav__item {
    color: #ffffff;
    &:hover {
      border-bottom: 3px solid #ffffff;
    }
  }
  .header__btn--create {
    background-color: #ffffff;
  }

  .header__btn--sign {
    border-color: #ffffff;
    color: #ffffff;
  }
}
